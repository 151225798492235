body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* filepath: /d:/my-portofolio-website/React-Portfolio/src/components/Tabs.css */
.react-tabs__tab-list {
    border-bottom: none;
    margin: 0 0 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;
}

.react-tabs__tab:hover {
    background: #eee;
    transform: translateY(-1px);
}

.react-tabs__tab--selected,
.react-tabs__tab--selected:hover {
    background: #F06000;
    color: white;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
    transform: none; /* This prevents the pressed effect */
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab-panel {
    display: none;
    padding: 24px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.react-tabs__tab-panel--selected {
    display: block;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}


/* Contact Form Styling */
#contact form {
    background: #fff;
    padding: 30px;
    border-radius: 12px;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
    max-width: 800px;
    margin: 0 auto;
}

#contact input,
#contact textarea {
    width: 100%;
    padding: 12px 15px;
    margin-bottom: 20px;
    border: 1px solid #e1e1e1;
    border-radius: 8px;
    background: #f5f5f5;
    color: #333;
    font-size: 16px;
    transition: all 0.3s ease;
}

#contact input:focus,
#contact textarea:focus {
    background: #fff;
    border-color: #F06000;
    box-shadow: 0 0 0 3px rgba(240, 96, 0, 0.1);
    outline: none;
}

#contact textarea {
    min-height: 150px;
    resize: vertical;
}

#contact input[type="submit"] {
    background: #F06000;
    color: white;
    font-weight: 500;
    padding: 12px 30px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    text-transform: uppercase;
    letter-spacing: 1px;
    width: auto;
    margin-top: 10px;
}

#contact input[type="submit"]:hover {
    background: #d65500;
    transform: translateY(-1px);
    box-shadow: 0 4px 12px rgba(240, 96, 0, 0.2);
}

#contact .lead {
    color: #666;
    text-align: center;
    margin-bottom: 30px;
    font-size: 18px;
}

#contact .section-head {
    text-align: center;
    margin-bottom: 40px;
}

/* Message states */
#message-warning, 
#message-success {
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
}

#message-warning {
    background: #fff3cd;
    color: #856404;
    border: 1px solid #ffeeba;
}

#message-success {
    background: #d4edda;
    color: #155724;
    border: 1px solid #c3e6cb;
}
