/* filepath: /d:/my-portofolio-website/React-Portfolio/src/components/Tabs.css */
.react-tabs__tab-list {
    border-bottom: none;
    margin: 0 0 20px;
    padding: 0;
    display: flex;
    justify-content: center;
    gap: 12px;
}

.react-tabs__tab:hover {
    background: #eee;
    transform: translateY(-1px);
}

.react-tabs__tab--selected,
.react-tabs__tab--selected:hover {
    background: #F06000;
    color: white;
    box-shadow: 0 4px 12px rgba(0, 123, 255, 0.2);
    transform: none; /* This prevents the pressed effect */
}

.react-tabs__tab:focus {
    outline: none;
}

.react-tabs__tab-panel {
    display: none;
    padding: 24px;
    border-radius: 12px;
    background: #fff;
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
}

.react-tabs__tab-panel--selected {
    display: block;
    animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

